.programs {
    padding: 150px 200px 50px 200px;
    // background-image: url('../../Assets/gbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1 {
        font-size: 2.5rem;
        color: #41b653;
        margin: 0 0 20px 0;
    }

    .programs-container {
        .program-card {
            background-color: #f5f5f5;
            margin-bottom: 15px;
            padding: 20px;
            border-radius: 10px;

            h2 {
                margin: 0;
                margin-bottom: 20px;
            }

            p {
                margin: 0;
                margin-bottom: 5px;
                font-size: 18px;
            }
        }
    }

    @media (max-width: 1280px) {
        padding: 5px 100px;

        .horo_img {
            img {
                width: 600px;
            }
        }
    }

    @media (max-width: 979px) {
        padding: 5px 50px;
        padding-top: 50px;

        .horo_img {
            img {
                width: 400px;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 5px 20px;
        padding-top: 120px;

        .horo_img {
            img {
                width: 400px;
            }
        }
    }
}