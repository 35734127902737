.Slider {
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto 30px auto;
}

.Slider .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Slider h1 {
  font-size: 2.5rem;
}

.Slider .bar {
  width: 180px;
  height: 0.3rem;
  background-color: #cd68ff;
  margin-bottom: 1rem;
  border-radius: 50px;
  margin-top: -0.5em;
  padding-top: 0;
}

.Slider .card {
  height: 16rem;
  width: 18rem;
  background-color: #41b65318;
  border-radius: 10px;
  padding: 1rem;
  justify-content: Left;
  margin: auto;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.Slider .card:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  transform: scale(1.05);
}

.Slider h4 {
  color: #000;
  margin: 10px 0;
  font-size: 1.7rem;
  height: 100px;
}

.Slider p {
  color: #6d6d6d;
  font-size: 1.2rem;
  line-height: 1.5;
}

.Slider a {
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
  padding: 10px 20px;
  background-color: #9d00ff;
  width: 100px;
  text-align: center;
  border-radius: 5px;
}

.Slider .carousel-container {
  height: 22em;
}

.Slider .custom-dot-list-style button {
  border: none;
}

@media screen and (max-width: 1600px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 20rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 2rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 16rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.7rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 20rem;
    height: 15rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 18rem;
    height: 20rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .Slider {
    width: 80%;
  }

  .Slider .card {
    width: 15rem;
    height: 16rem;
  }

  .Slider h4 {
    margin: 10px 0;
    font-size: 1.6rem;
  }

  .Slider p {
    font-size: 1rem;
  }
}