.contact-us-page {
    padding: 100px 200px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // background-image: url('../../Assets/gbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1280px) {
        padding: 50px 100px;
    }

    @media (max-width: 979px) {
        padding: 50px 50px;
    }

    @media (max-width: 768px) {
        padding: 30px;
    }


    .title-top {
        margin-top: 40px;

        @media (max-width: 768px) {
            margin-top: 80px;
        }
    }

    .contact-info {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 10px;
        width: 500px;
        flex-direction: column;
        margin-top: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
            width: 100%;

            .ant-divider {
                margin: 10px 0;
            }
        }

        a {
            color: #41b653;
            text-decoration: none;
            transition: all 0.3s ease;
            font-size: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .anticon {
                font-size: 30px;
            }

            p {
                font-size: 20px;
                text-align: center;
            }

            &:hover {
                color: #3b41c9;
                transform: scale(1.1);
                transition: all 0.3s ease;
            }

            @media (max-width: 768px) {
                font-size: 18px;
                padding: 0;

                .anticon {
                    font-size: 25px;
                }

                p {
                    font-size: 18px;
                }
            }
        }
    }

}