.attendance {
    padding: 20px;

    .header {
        display: flex;
        align-items: start;
        gap: 20px;
        margin: 0;

        h1 {
            margin: 0;
        }
    }

    .search {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;
    }

    .students {
        .student {
            background-color: #e4e4e45e;
            margin-bottom: 15px;
            padding: 0 20px 10px 20px;
            border-radius: 10px;

            .info {
                h2 {
                    margin: 0;
                }

                display: flex;
                align-items: center;
                gap: 20px;
            }

            .data {
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
    }
}