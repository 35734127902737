.signin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    background-color: #f5f5f5;

    .container {
        width: 500px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.049);

        .signin-header {
            margin-bottom: 20px;
            text-align: center;
            margin-top: 30px;

            h1 {
                font-size: 2.5rem;
                color: #333;
                margin: 0 0 20px 0;
            }

            p {
                font-size: 1.2rem;
                margin: 0;
                color: #333;
            }
        }

        .form {
            padding: 20px 60px;

            .form-group {
                margin-bottom: 20px;

                label {
                    font-size: 1.2rem;
                    color: #333;
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #333;
                    border-radius: 5px;
                    font-size: 1rem;
                }
            }

            .form-group:last-child {
                margin-bottom: 0;
            }

            .form-group button {
                width: 100%;
                padding: 10px;
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                background-color: #333;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}