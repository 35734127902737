/*
////////////////////
/// 
Global Styles

_____________________________________________________________________
 */

.title-top {
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 10px 0;

  @media (max-width: 1280px) {
    font-size: 1.5rem;
  }
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #41b653;

  @media (max-width: 1480px) {
    font-size: 2rem;
  }

  span {
    color: #000;
  }
}

.para {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7;

  @media (max-width: 1480px) {
    font-size: 1rem;
  }
}

/*
////////////////////
/// 
Hero

_____________________________________________________________________
 */

.home-page {
  width: 100%;
}

.home-page .hero {
  display: flex;
  padding: 100px 200px 0 200px;
  color: #1a1a1a;
  gap: 20px;
  height: 60vh;

  @media (max-width: 1440px) {
    height: 40vh;
    padding: 100px 160px 0 160px;
  }

  @media (max-width: 1366px) {
    height: 50vh;
  }

  @media (max-width: 1280px) {
    padding: 100px 100px 0 100px;
  }

  @media (max-width: 768px) {
    padding: 100px 30px 0 30px;
    flex-direction: column;
  }

  @media (max-width: 580px) {
    height: 80vh;
  }

  .hero-content {
    flex: 1;
    border-radius: 20px;
    background-color: hsla(129, 47%, 48%, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    padding: 20px 40px;

    @media (max-width:1024px) {
      padding: 20px;
      flex: 2;
    }

    @media (max-width: 768px) {
      padding: 20px;
      align-items: center;
      text-align: center;
      flex: 1;
    }
  }

  .horo_img {
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
    display: block;

    @media (max-width:1024px) {
      flex: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.home-page .hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  width: 100%;
  text-align: start;
  margin: 0;

  span {
    font-size: 5rem;
  }

  @media (max-width: 1440px) {
    font-size: 2rem;

    span {
      font-size: 3rem;
    }
  }

  @media (max-width: 1024px) {
    font-size: 1.5rem;

    span {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 2rem;

    span {
      font-size: 3.5rem;
    }
  }

  @media (max-width: 580px) {
    font-size: 1.5rem;

    span {
      font-size: 2rem;
    }
  }
}

.home-page .hero h4 {
  font-size: 1.1rem;
  font-weight: 400;
  width: 100%;
  text-align: start;
  line-height: 1.5;

  @media (max-width: 1440px) {
    font-size: 1rem;
  }
}

.home-page .hero .hero-buttons {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}

.home-page .hero .hero-buttons a {
  text-decoration: none;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  background-color: #1a1a1a;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 10px 20px;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    padding: 15px 20px;
    width: 140px;
  }
}

.home-page .hero .hero-buttons a:hover {
  background-color: #fff;
  color: #41b653;
  transition: all 0.3s ease;
}

.home-page .hero .hero-buttons .border-btn {
  border: 3px solid #1a1a1a;
  color: #1a1a1a;
  background-color: #fff;
}

.home-page .hero .hero-buttons .border-btn:hover {
  background-color: #1a1a1a;
  color: #fff;
  transition: all 0.3s ease;
}

/* 
///////////////

About

_____________________________________________________________________
 */
.home-page {
  .about {
    padding: 20px 200px;
    display: flex;
    align-items: center;

    @media (max-width: 1440px) {
      padding: 20px 160px;
    }

    @media (max-width: 1280px) {
      padding: 20px 100px;
    }

    @media (max-width: 979px) {
      padding: 20px 50px;
    }

    @media (max-width: 768px) {
      padding: 20px 30px;
      flex-direction: column;
    }

    .about_cards {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 768px) {
        gap: 10px;
      }

      @media (max-width: 580px) {
        flex-direction: column;
      }

      .card1 {
        background-color: hsla(238, 39%, 45%, 1);
        color: #fff;
      }

      .card2 {
        background-color: hsla(13, 89%, 89%, 1);
        color: #1a1a1a;
      }

      .card3 {
        background-color: hsla(199, 74%, 54%, 1);
        color: #1a1a1a;
      }

      .card {
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 20px;
        line-height: 1.5;
        min-height: 200px;

        @media (max-width: 1440px) {
          min-height: 300px;
        }

        @media (max-width: 1280px) {
          min-height: 220px;
        }

        @media (max-width: 768px) {
          min-height: 300px;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          gap: 0;
        }

        @media (max-width: 580px) {
          min-height: 100%;
        }

        .left {
          flex: 2;

          h1 {
            margin: 0;
            line-height: 1.2;

            @media (max-width: 768px) {
              flex: 1;
              font-size: 1.5rem;
            }
          }

          @media (max-width: 768px) {
            flex: 1;
          }
        }

        .image {
          flex: 1;
          display: block;

          img {
            width: 100%;

            @media (max-width: 768px) {
              width: 60%;
            }

            @media (max-width: 580px) {
              width: 30%;
            }
          }
        }
      }
    }
  }
}


.video {
  padding: 20px 200px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1440px) {
    padding: 20px 160px;
  }

  @media (max-width: 1280px) {
    padding: 20px 100px;
  }

  @media (max-width: 979px) {
    padding: 20px 50px;
  }

  @media (max-width: 768px) {
    padding: 30px;
    flex-direction: column;
  }

  iframe {
    flex: 1;
    min-height: 400px;
    border: none;
    border-radius: 20px;
    background-color: #41b6533d;

    @media (max-width: 1024px) {
      min-height: 300px;
    }

    @media (max-width: 768px) {
      min-height: 300px;
      width: 100%;
    }
  }

  .video-content {
    flex: 1;
    background-color: hsla(56, 88%, 78%, 1);
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    border-radius: 20px;

    h2 {
      font-size: 2.3rem;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5;
    }

    @media (max-width: 1024px) {
      min-height: 300px;

      h2 {
        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }
    }

    @media (max-width: 768px) {
      min-height: 200px;

      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }
}

/*
////////////////////

Services

_____________________________________________________________________
 */

.home-page {
  .services {
    padding: 50px 0 20px 0;

    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }

    .services-inner {
      text-align: center;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .services-btn {
      display: flex;
      justify-content: center;

      a {
        text-decoration: none;
        color: #fff;
        padding: 15px 30px;
        border-radius: 50px;
        border: 1px solid #41b653;
        background-color: #fff;
        color: #41b653;
        transition: all 0.3s ease;
        font-size: 1.2rem;
        margin: 0 auto;
      }

      a:hover {
        background-color: #41b653;
        border: 1px solid #41b653;
        color: #fff;
        transition: all 0.3s ease;
      }
    }
  }
}



/*
////////////////////

Products

_____________________________________________________________________
 */

.home-page {
  .home-products {
    background-color: #41b65318;

    @media (max-width: 768px) {
      .ant-image {
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .home-products-top {
      padding-top: 50px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .home-products-top-left {
        width: 60%;

        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
        }
      }

      p {
        width: 60%;

        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }
    }

    .home-products-bottom {
      .mapComp {
        position: relative;
        margin-top: 35px;

        @media (max-width: 768px) {
          margin-top: 30px;
        }

        iframe {
          width: 100%;
          height: 700px;
          border: none;

          @media (max-width: 768px) {
            height: 400px;
          }
        }

        .searchCenters {
          position: absolute;
          bottom: 10px;
          left: 10px;

          .ant-btn {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background-color: #41b653;
            color: #fff;
          }
        }


        .centers {
          background-color: #fff;
          position: absolute;
          top: 40px;
          right: 40px;
          border-radius: 20px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          overflow: hidden;

          @media (max-width: 768px) {
            top: 20px;
            right: 10px;
          }

          h2 {
            font-size: 1.5rem;
            margin: 0;
            padding: 20px 50px;
            background-color: #41b653;
            color: #fff;

            @media (max-width: 768px) {
              font-size: 1.2rem;
              padding: 10px 20px;
            }
          }

          .centerName {
            height: 400px;
            overflow-y: scroll;

            @media (max-width: 768px) {
              height: 300px;
            }

            &::-webkit-scrollbar {
              width: 12px;
            }

            &::-webkit-scrollbar-track {
              background: #fff;
            }

            &::-webkit-scrollbar-thumb {
              background: #41b65318;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            scrollbar-width: thin;
            scrollbar-color: #41b65318 #fff;

            div {
              background-color: #fff;
              cursor: pointer;
              width: 350px;

              span {
                display: block;
                padding: 20px;
                border-bottom: 1px solid #41b65318;
                transition: all 0.3s ease;
                cursor: pointer !important;

                &:hover {
                  font-size: 1.2rem;
                }
              }

              @media (max-width: 768px) {
                width: 100%;

                span {
                  padding: 15px;
                }
              }
            }

            .even {
              background-color: #41b65318;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

/*
////////////////////

Reviews

_____________________________________________________________________
 */

.home-page {
  .home-reviews {
    padding: 100px 200px;

    @media (max-width: 1280px) {
      padding: 50px 100px;
    }

    @media (max-width: 979px) {
      padding: 50px 50px;
    }

    @media (max-width: 768px) {
      padding: 30px;
      flex-direction: column;
    }

    // .home-reviews-bottum {
    //   margin-top: 50px;

    //   .review-slider {
    //     .card {
    //       background-color: #fcf8ff;
    //       display: flex;
    //       width: 100%;
    //       margin: 0 auto;
    //       padding: 30px;

    //       @media (max-width: 768px) {
    //         flex-direction: column;
    //       }

    //       .card-image {
    //         width: 15%;

    //         @media (max-width: 1400px) {
    //           width: 20%;
    //           text-align: center;
    //         }

    //         @media (max-width: 768px) {
    //           width: 40%;
    //           text-align: center;
    //         }

    //         .image {
    //           border-radius: 100%;
    //           margin-right: 20px;
    //           overflow: hidden;

    //           img {
    //             width: 100%;
    //             object-fit: cover;
    //             display: block;
    //           }
    //         }
    //       }

    //       .card-content {
    //         width: 80%;
    //         margin-left: 30px;

    //         @media (max-width: 768px) {
    //           margin-left: 0px;
    //         }

    //         h4 {
    //           font-size: 2.4rem;
    //           margin: 0 0 5px 0;
    //         }

    //         h3 {
    //           font-size: 1.4rem;
    //           margin: 0;
    //           margin-bottom: 20px;
    //           color: #7a7a7a;
    //           font-weight: 400;
    //         }

    //         p {
    //           font-size: 1.2rem;
    //           margin: 0;
    //           line-height: 1.5;
    //           margin-bottom: 20px;
    //           width: 90%;
    //         }

    //         a {
    //           color: #41b653;
    //           text-decoration: none;
    //           font-size: 1.2rem;
    //         }

    //         @media (max-width: 1280px) {
    //           h4 {
    //             font-size: 2rem;
    //           }

    //           h3 {
    //             font-size: 1.2rem;
    //           }

    //           p {
    //             font-size: 1rem;
    //           }

    //           a {
    //             font-size: 1rem;
    //           }
    //         }
    //       }
    //     }

    //     .custom-dot-list-style button {
    //       border: none;
    //     }
    //   }
    // }
  }
}

/*
////////////////////

Team

_____________________________________________________________________
 */

.home-page {
  .home-team {
    padding: 50px 200px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1280px) {
      padding: 50px 100px;
    }

    @media (max-width: 979px) {
      padding: 50px 50px;
    }

    @media (max-width: 768px) {
      padding: 30px;
      flex-direction: column;
    }

    .home-team-left {
      width: 60%;

      @media (max-width: 1280px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }

      .home-team-cards {
        display: flex;

        @media (max-width: 768px) {
          margin-left: 40px;
        }

        .home-team-cards-item {
          border-radius: 100%;
          overflow: hidden;
          transition: all 0.3s ease;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

          @media (max-width: 768px) {
            margin: 0 auto;
            margin-bottom: 20px;
          }

          .card-details {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            h2 {
              font-size: 1rem;
              margin: 0;
              margin-bottom: 10px;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;

              a {
                color: #fff;
                text-decoration: none;
                font-size: 1.2rem;
                transition: all 0.3s ease;

                &:hover {
                  color: #e70f77;
                  transform: scale(1.1);
                  transition: all 0.3s ease;
                  z-index: 1000;
                }
              }
            }

            @media (max-width: 1280px) {
              h2 {
                font-size: 1rem;
              }

              div {
                a {
                  font-size: 1rem;
                }
              }
            }

            @media (max-width: 768px) {
              h2 {
                font-size: 0.5rem;
                margin: 0;
              }

              div {
                a {
                  font-size: 1rem;
                }
              }
            }
          }

          &:hover {
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 1000;
          }
        }

        // chnage position of cards
        .home-team-cards-item:nth-child(1) {
          transform: scale(1) translate(0px, 0px);

          &:hover {
            transform: scale(1.2) translate(0px, 0px);
          }
        }

        .home-team-cards-item:nth-child(2) {
          transform: scale(1) translate(-20px, 0px);

          &:hover {
            transform: scale(1.2) translate(-20px, 0px);
          }
        }

        .home-team-cards-item:nth-child(3) {
          transform: scale(1) translate(-40px, 0px);

          &:hover {
            transform: scale(1.2) translate(-40px, 0px);
          }
        }

        .home-team-cards-item:nth-child(4) {
          transform: scale(1) translate(-60px, 0px);

          &:hover {
            transform: scale(1.2) translate(-60px, 0px);
          }
        }
      }
    }

    .home-team-right {
      margin-top: 10px;
      width: 30%;

      @media (max-width: 1280px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

/*
////////////////////

Start

_____________________________________________________________________
 */

.home-page {
  .home-start {
    padding: 50px 200px;

    @media (max-width: 1280px) {
      padding: 50px 100px;
    }

    @media (max-width: 979px) {
      padding: 50px 50px;
    }

    @media (max-width: 768px) {
      padding: 30px;
    }

    .home-start-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(90deg, #9302f0 0%, #cd7cff 100%);
      border-radius: 30px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .home-start-inner-left {
        width: 40%;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;

        @media (max-width: 1680px) {
          padding: 40px 0 40px 40px;
        }

        h3 {
          font-size: 2.5rem;
          font-weight: 700;
          margin: 0 0 10px 0;
          color: #fff;
        }

        p {
          margin: 30px 0;
          font-size: 1.2rem;
          color: #ffffff;
          line-height: 1.5;
        }

        @media (max-width: 979px) {
          width: 50%;

          h3 {
            font-size: 2rem;
          }

          p {
            font-size: 1rem;
          }
        }

        @media (max-width: 768px) {
          width: 90%;
          padding-left: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h3 {
            font-size: 2rem;
            margin: 0;
          }

          p {
            font-size: 1rem;
            margin: 20px 0;
          }
        }

        button {
          cursor: pointer;
          font-weight: 700;
          font-family: Helvetica, "sans-serif";
          transition: all 0.2s;
          padding: 10px 20px;
          border-radius: 100px;
          background: #e70f77;
          border: 1px solid transparent;
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #fff;
        }

        button:hover {
          background: #920348;
        }

        button>svg {
          width: 34px;
          margin-left: 10px;
          transition: transform 0.3s ease-in-out;
          color: #fff;
        }

        button:hover svg {
          transform: translateX(5px);
        }

        button:active {
          transform: scale(0.95);
        }
      }

      .home-start-inner-right {
        width: 60%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}



// Memory lean


.MemoryCorner {
  padding: 0px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MemoryCorner #topic {
  font-size: 2.5rem;
}

.MemoryCorner .bar {
  width: 150px;
  height: 0.3rem;
  background-color: #cd68ff;
  margin-bottom: 1rem;
  margin-top: -1em;
  border-radius: 50px;
}

.MemoryCorner h3 {
  font-weight: 400;
  margin: 20px 0 40px 0;
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .MemoryCorner {
    margin-top: 10px;
    padding: 10px 20px 50px 20px;
  }

  .MemoryCorner h3 {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
}

.MemoryCorner .imgageContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.MemoryCorner .imgageContainer img {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

@media (max-width: 1080px) {
  .MemoryCorner .imgageContainer img {
    height: 200px;
    width: 300px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .MemoryCorner .imgageContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .MemoryCorner .imgageContainer img {
    width: 100% !important;
    height: 200px !important;
    object-fit: cover;
  }
}

.pinkBar {
  background: linear-gradient(0deg, #370051 0%, #7c31a2 100%);
  width: 100%;
  height: 6rem;
  padding-top: 0;
}

@media (max-width: 650px) {
  .pinkBar {
    height: 4rem;
  }
}