.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 5px 200px;
  box-sizing: border-box;
  /* Include padding in width calculation */
  display: flex;
  justify-content: space-between;
  /* Align logo and menu items */
  align-items: center;
  /* Center items vertically */
  transition: all 0.3s ease;
  z-index: 1000;

  @media (max-width: 1280px) {
    padding: 5px 100px;
  }

  @media (max-width: 979px) {
    padding: 5px 50px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: -1;
}

.navbar--scroll {
  /* background-color: #fff; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;
}

.navbar {
  background-color: transparent;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.navbar--scroll::before {
  height: 100%;
  transition: all 0.3s ease;
}

.navbar .logo {
  padding: 10px 0;
  width: 240px;
}

.navbar .logo img {
  width: 100%;
}

.navbar .nav-links {
  display: flex;
  gap: 30px;
  align-items: center;
}

.navbar a {
  text-decoration: none;
  font-size: 20px;
  color: #000;
  transition: all 0.3s ease;
}

.navbar .nav-links a:hover {
  transform: scale(1.1);
}

.navbar--home a {
  color: #000;
}

.navbar .activenav {
  color: #41b653 !important;
  font-weight: 600;
}

.navbar .activenav #lightnav {
  color: #eed2ff;
}


.navbar--scroll a {
  color: #333;
}

.nav-contact {
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-contact a {
  color: #fff;
  background-color: #41b653;
  padding: 10px 20px;
  border-radius: 5px;
}

.nav-contact .reg {
  color: #41b653;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.navbar--scroll .nav-contact a {
  color: #fff;
}

.navbar--scroll .nav-contact .reg {
  color: #41b653;
}

.nav-contact a:hover {
  transition: all 0.3s ease;
  transform: scale(1.1);
}

.content {
  padding: 80px 20px 20px;
  margin-top: 60px;
}



// 
//-----------------------------------------------------------------------
// Mobile Navbar
//
//_____________________________________________________________________

.mobile-nav-toggle {
  position: fixed;
  top: 15px;
  right: 5vw;
  z-index: 11000;
  width: 90vw;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: #ffffff8f;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    display: flex;
  }

  .logo {
    width: 140px;

    img {
      width: 100%;
    }
  }

  .hamburger {
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      font-family: inherit;
    }

    .menu__icon {
      width: 32px;
      height: 32px;
      padding: 4px;
    }

    .menu__icon span {
      display: block;
      width: 100%;
      height: 0.125rem;
      border-radius: 2px;
      background-color: #41b653;
      box-shadow: 0 .5px 2px 0 hsla(0, 0%, 0%, .2);
      transition: background-color .4s;
      position: relative;
    }

    .menu__icon span+span {
      margin-top: .375rem;
    }

    .menu__icon span:nth-child(1) {
      animation: ease .8s menu-icon-top-2 forwards;
    }

    .menu__icon span:nth-child(2) {
      animation: ease .8s menu-icon-scaled-2 forwards;
    }

    .menu__icon span:nth-child(3) {
      animation: ease .8s menu-icon-bottom-2 forwards;
    }

    .active-menu span:nth-child(1) {
      animation: ease .8s menu-icon-top forwards;
    }

    .active-menu span:nth-child(2) {
      animation: ease .8s menu-icon-scaled forwards;
    }

    .active-menu span:nth-child(3) {
      animation: ease .8s menu-icon-bottom forwards;
      background-color: #3b41c9;
    }

    @keyframes menu-icon-top {
      0% {
        top: 0;
        transform: rotate(0);
      }

      50% {
        top: .5rem;
        transform: rotate(0);
      }

      100% {
        top: .5rem;
        transform: rotate(45deg);
      }
    }

    @keyframes menu-icon-top-2 {
      0% {
        top: .5rem;
        transform: rotate(45deg);
      }

      50% {
        top: .5rem;
        transform: rotate(0);
      }

      100% {
        top: 0;
        transform: rotate(0);
      }
    }

    @keyframes menu-icon-bottom {
      0% {
        bottom: 0;
        transform: rotate(0);
      }

      50% {
        bottom: .5rem;
        transform: rotate(0);
      }

      100% {
        bottom: .5rem;
        transform: rotate(135deg);
      }
    }

    @keyframes menu-icon-bottom-2 {
      0% {
        bottom: .5rem;
        transform: rotate(135deg);
      }

      50% {
        bottom: .5rem;
        transform: rotate(0);
      }

      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }

    @keyframes menu-icon-scaled {
      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(0);
      }
    }

    @keyframes menu-icon-scaled-2 {
      0% {
        transform: scale(0);
      }

      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}

.mobile-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s ease;
  display: none;
  z-index: -1;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  .mobile-nav-box {
    width: 90vw;
    height: 90vh;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    .mobile-nav-links {
      display: none;
    }

    .mobile-nav-contact {
      display: none;
    }
  }
}

.mobile-nav-container-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s ease;
  overflow: hidden;

  .mobile-nav-box {
    width: 90vw;
    height: 80vh;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;

    @media screen and (max-height: 850px) {
      height: 75vh;
    }

    @media screen and (max-height: 700px) {
      height: 70vh;
    }

    .mobile-nav-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;

      a {
        text-decoration: none;
        font-size: 18px;
        color: #333;
        transition: all 0.3s ease;
      }

      a:hover {
        transform: scale(1.1);
      }
    }

    .mobile-nav-contact {
      background-color: #41b653;
      padding: 10px 20px;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s ease;

      a {
        text-decoration: none;
        font-size: 18px;
        color: #fff;
        transition: all 0.3s ease;
      }
    }

    .mobile-nav-contact:hover {
      transition: all 0.3s ease;
      transform: scale(1.1);
    }
  }
}