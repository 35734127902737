.admin {
    padding: 6em 6em 5em 6em;
    min-height: 80vh;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .admin-info {
            display: flex;
            align-items: center;
            gap: 20px;

            p {
                text-align: end;

                span {
                    color: #7d7d7d;
                }
            }
        }
    }
}