.training {
    .header {
        display: flex;
        justify-content: space-between;
        /* Adjust for better spacing on smaller screens */
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        /* Allows wrapping on smaller screens */

        h1 {
            font-size: 24px;
            /* Adjust font size for better readability */
        }

        .ant-select {
            width: 100%;
            max-width: 200px;
            /* Ensures dropdown isn't too wide */
        }
    }

    .trins {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .program {
            background-color: #f2f2f2bd;
            margin: 0 0 40px 0;
            padding: 20px;
            border-radius: 10px;

            h2,
            h3 {
                margin: 0 0 10px 0;
            }

            .description {
                display: flex;
                flex-direction: column;
                /* Stack descriptions on smaller screens */
                gap: 10px;
            }

            p {
                font-size: 16px;
                margin: 0 0 10px 0;
            }

            a {
                margin-top: 10px;
                color: #9d00ff;
                text-decoration: none;
                font-size: 16px;
                font-weight: 700;
                display: inline-block;
                padding: 10px 20px;
                background-color: #9d00ff1f;
                border-radius: 5px;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #9d00ff3f;
                }
            }

            .contents {
                display: flex;
                flex-direction: column;
                /* Stack videos on smaller screens */
                align-items: center;
                gap: 20px;

                @media (min-width: 768px) {
                    flex-direction: row;
                    /* Row layout on larger screens */
                    align-items: flex-start;
                    gap: 50px;
                }

                iframe {
                    width: 100%;
                    max-width: 400px;
                    /* Ensure video iframe resizes */
                    height: auto;
                    /* Maintain aspect ratio */
                }
            }
        }
    }
}