.regsiter {
    padding-top: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
    padding-bottom: 5em;

    @media (max-width: 768px) {
        padding: 7em 20px 5em 20px;
    }

    .container {
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
    }

    h1 {
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 10px;
        text-align: center;
    }

    h2 {
        font-size: 1em;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: center;
        line-height: 2;
        margin-bottom: 30px;
    }

    .ant-btn {
        width: 200px;
        margin: 20px auto 0 auto;
        height: 50px;
        background-color: #41b653;

        &:hover {
            background-color: #328c40 !important;
        }
    }
}